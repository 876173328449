var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.list_items)?_c('v-autocomplete',{attrs:{"item-value":'node.id',"filter":_vm.customFilter,"search-input":_vm.search,"hide-no-data":"","items":_vm.items_list,"loading":_vm.loading,"label":_vm.label,"readonly":_vm.readonly,"clearable":_vm.clearable,"rules":[function (v) { return (_vm.rules ? !!v : true) || _vm.label + ' obligatoire'; }],"dense":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.item_change,"input":_vm.item_select,"click:clear":_vm.clear_click},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var node = ref.item.node;
return [_vm._v(" "+_vm._s(node ? _vm.list_label(node) : _vm.item_id)+" ")]}},{key:"item",fn:function(ref){
var node = ref.item.node;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.list_label(node))}}),(_vm.subtitle)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
            (node.unit_name ? node.unit_name : 'Quantité') +
            ' : ' +
            (node.stock_cmpt ? node.stock_cmpt : 0)
          )}}):_vm._e(),(node.statuts_name)?_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"small":"","color":node.color}},[_vm._v(" "+_vm._s(node.statuts_name)+" ")])],1):_vm._e()],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}],null,false,3535935540),model:{value:(_vm.item_id),callback:function ($$v) {_vm.item_id=$$v},expression:"item_id"}}):_vm._e(),(_vm.list_items)?_c('v-autocomplete',{attrs:{"item-value":_vm.item_value,"item-text":_vm.item_text,"filter":_vm.customFilter,"search-input":_vm.search,"hide-no-data":"","items":_vm.items_list,"loading":_vm.loading,"label":_vm.label,"readonly":_vm.readonly,"clearable":_vm.clearable,"rules":[function (v) { return (_vm.rules ? !!v : true) || _vm.label + ' obligatoire'; }],"dense":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.item_change,"input":_vm.item_select,"click:clear":_vm.clear_click},model:{value:(_vm.item_id),callback:function ($$v) {_vm.item_id=$$v},expression:"item_id"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }